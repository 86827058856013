import { Component } from '@angular/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { StatusbarService } from './services/StatusBar/statusbar.service';
import { Platform, ToastController } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { PushService } from './services/notifications/push.service';
import { SocketService } from './services/socket.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private _platform: Platform,
    private _statusBar: StatusbarService,
    private _push: PushService,
    public wsService: SocketService,
    private toastController: ToastController
  ) {
    this.initializeApp();
  }

  async initializeApp() {



    this._platform.ready().then(() => {
      if (Capacitor.getPlatform() == "android") {
        //TODO: Llamar al init de push Service
        this._push.init();
      }
    });


    this.wsService.listen('new-event').subscribe(
      async (event: any) => {
        console.log(event);

        const toast = await this.toastController.create({
          header: event.title,
          message: event.description,
          duration: 5000,
          position: 'top',
          color: 'success',
          icon: 'rocket-outline'
        });

        await toast.present();

      }
    )

  }
}
