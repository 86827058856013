import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  public status: boolean = false;

  public users: number = 0;

  constructor(private socket: Socket) {

    this.checkStatus();
    this.setConnectedUsers();

   }

   setConnectedUsers(){
    this.listen('connected_users').subscribe( (payload: any)  => {

      // console.log(payload);
      this.users = payload.connected_users

    });
   }

  checkStatus(){

    this.socket.on('connect', () => {
      // console.log('Conectado al servidor');
      this.status = true;
    });

    this.socket.on('disconnect', () => {
      // console.log('Desconectado del servidor');
      this.status = false;
    });

  }

  listen(event: string): Observable<Socket> {
    return this.socket.fromEvent(event);
  }

}
