import { Injectable } from '@angular/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Platform } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class StatusbarService {

  constructor(private _platform: Platform) {
    // this.overlay(true);
  }

  public overlay(val: boolean): void{ 
    if( this.isMobile() ){
      StatusBar.setOverlaysWebView({ overlay: val });
      if(val == false) StatusBar.setStyle({ style: Style.Light });
    }
  }

  public async setBackground(color: string){
    if( this.isMobile() ){
      await StatusBar.setBackgroundColor({ color });
    }
  }

  private isMobile(){
    return this._platform.is("mobile") && !this._platform.platforms().includes('mobileweb'); 
  }

}
