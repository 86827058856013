// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // wsUrl: "http://192.168.0.179:3000",
  // api: "http://192.168.0.179:3000/api",

  // api: "https://stg.api.ijoda.com.ar/api",
  // wsUrl: "https://stg.api.ijoda.com.ar",

  // wsUrl: "http://192.168.0.179:3000",
  // api: "http://192.168.0.179:3000/api",

  api: "http://localhost:3000/api",
  wsUrl: "http://localhost:3000",
  
  VERSION: "1.3.1",
  V_TYPE: "BETA",
  one_signal_id: "3336ecbb-a23c-4ed8-82a9-b240bc186e5c",
  // MP_PUBLIC_KEY: "APP_USR-7b17934c-2a90-4647-b051-7e85fffdf7a2"
  MP_PUBLIC_KEY: "APP_USR-5615ab1a-6ff1-4557-8df4-51552aeae7c9"
  
};
 
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
